<template>
  <div class="page">
    <div class="details-box">
      <div class="title-box">
        <div class="title-top">
          <span>{{ demand.title }}</span>
          <span class="salary">{{ demand.remuneration }}</span>
        </div>
        <div class="title-bottom">
          <span>
            <van-icon name="location-o" /><span class="title-tip">{{
              demand.workingPlaceName
            }}</span>
          </span>
          <!-- <span style="margin-left: 15px">
            <van-icon name="coupon-o" /><span class="salary" >经验两年以上</span>
          </span> -->
          <span style="margin-left: 15px">
            <van-icon name="underway-o" /><span class="salary"
              >工期{{ demand.projectDuration }} 天</span
            >
          </span>
        </div>
      </div>

      <div class="progress">
<!--        <div class="progress-van">-->
<!--          <h5>进度</h5>-->
<!--          <van-progress :percentage="50" />-->
<!--        </div>-->
        <div class="card">
          <div class="boss-photo">
<!--            <img :src="demand.createdUserAvatar" alt="" />-->
            <img style="border-radius: 50%;" v-if="demand.type=='RECRUITMENT'" src="@/assets/images/zg.jpg" alt="" />
            <img v-else src="@/assets/images/xq.jpg" style="border-radius: 50%;" alt="" />
          </div>
          <div class="boss-info">
            <div class="con-top">
              <div class="boss-name">
                {{demand.contactsName.substring(0,1)}}**
                <!-- <span>18812345626</span> -->
              </div>
            </div>
            <div class="release">发布于: {{demand.createdDate}}</div>
          </div>
        </div>
      </div>
      <!--项目描述-->
      <div class="project">
        <div class="describe">
          <div class="tip">项目描述</div>
          <div class="work-type">
            所需工种:
            <span v-for="(item, index) in demand.workerSkill" :key="index">
              <span>{{ item }}</span>
            </span>
          </div>
          <div class="treatment">
            <p v-for="(item,i) in demand.workerInfos" :key="i">
              <van-icon name="info-o" color="#7A9DE5" />: {{item.workType}} 招{{item.peopleQty}}人,{{item.remuneration}}
            </p>
          </div>
          <div class="first" v-text="demand.content">
            
          </div>
        </div>
      </div>
      <!--工作地址-->
      <div class="work-place">
        <div class="tip">工作地址</div>
        <p v-text="demand.workingPlaceName"></p>
        <!-- <div class="map"></div> -->
      </div>

      <!--安全提示-->
      <div class="safety-tip">
        <div class="tip">
          <van-icon name="add" color="#1989fa" /><span>安全提示</span>
          <p>
            严谨用人单位做出任何损害工友合法权益的违法违规行为，包括
            不限于信息不属实、扣押工友的证件、诱导收取费用等，一旦发
            现此类行为，
            <a href="">请立即举报</a>
          </p>
        </div>

        <div class="map"></div>
      </div>
    </div>
    <div class="footer" v-if="!demand.isOver&&demand.status!='FINISH'&&demand.status!='FAIL'">
      <div class="footer-btn">
        <van-button round type="info" plain style="width: 100px" @click="mask=true">分享</van-button>
        <van-button
            v-if="!tel"
          round
          type="info"
          size="normal"
          style="width: 100px; margin-left: 10px"
          @click="getPhone"
          >联系方式</van-button>
        <van-button
            v-if="tel"
            round
            type="info"
            size="normal"
            style="width: 100px; margin-left: 10px"
            @click="showPhone"
        >电话联系</van-button>
      </div>
    </div>
    <a :href="tel" ref="telPhone" style="display:none;"></a>

    <div class="mask" @touchmove.prevent @click="mask=false"  v-if="mask == true">
      <div class="maskImg">
        <img src="@/assets/images/jt.png" alt />

      </div>
      <div class="share-txt">
        <div class="title">立即分享给好友吧</div>
        <div style="font-size: 18px;">点击屏幕右上角分享给好友</div>
      </div>
    </div>

  </div>
</template>


<script>
import {checkPhone, get, getPhone} from '../../api/demand/index'
import { Dialog,Toast,Notify } from 'vant';
import {getTicket} from "../../api/auth";
import {getDicts,getDictsMap} from "../../api/dict";
import wx from 'weixin-js-sdk'
import store from "../../store"
import * as demandApi from "../../api/demand";
export default {
  props: ["id"],
  data() {
    return {
      integral: {
        查看联系方式: 0
      },
      mask: false,
      tel: undefined,
      userInfo: undefined,
      demand: {
        photo: require("@/assets/images/qizhongji.png"),
        recruitmentTitle: "高新汉裕金谷招水电工8名",
        describe:
          "要求:干活实在,有实力的人,项目稳定,资金没有问题,包吃包住,地点是在济南高新汉裕金谷,具体事宜请与项目经理王老板电话联系.",
        workPlace: "济南高新",
        days: "6天",
        date: "2021.11.4",
        salary: "300元/日",
      },
      pid: undefined,
    };
  },
  created(e) {
    console.info("detail created:",this.$route.query.pid)
    this.pid = this.$route.query.pid;
    if (this.pid){
      //todo 发送请求道后台记录邀请人
    }
  },
  mounted() {
    this.userInfo = JSON.parse(`${store.state.user}`)
   this.loadData()
    this.loadTicket();
    this.loadDictData();
  },
  methods: {
    async loadDictData() {
      this.integral = await getDictsMap({ name: "积分类型" })
    },
      async loadData(){
        this.demand = await get(this.id)
        this.checkPhone()
      },
    async loadTicket(){
      let _this = this;
      let r = await getTicket({page:"demand/"+this.id});
      let ticket = r.data;
      wx.config({
        // debug: true, // 开启调试模式
        appId: ticket.appId, // 必填，公众号的唯一标识
        timestamp: ticket.timestamp, // 必填，生成签名的时间戳
        nonceStr: ticket.nonceStr, // 必填，生成签名的随机串
        signature: ticket.signature,// 必填，签名
        jsApiList: [
          "updateAppMessageShareData",//自定义“分享给朋友”及“分享到QQ”按钮的分享内容
          "updateTimelineShareData",//自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
          'onMenuShareAppMessage',  //旧的接口，即将废弃
          'onMenuShareTimeline'
        ] // 必填，需要使用的JS接口列表
      })
      //通过ready接口处理成功验证
      wx.ready(function(){
        _this.wxShareTimeline();
        // this.wxShareAppMessage();

// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      });
    },
    wxShareTimeline() {// 自定义“分享给朋友”及“分享到QQ”按钮的分享内容

      wx.onMenuShareAppMessage({
        title: '【活可多】起重人才聚集地', // 分享标题
        desc: '起重招人就上“活可多”，靠谱工匠随心选', // 分享描述
        link: "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1a86754b82cee013&redirect_uri=http://mp.91workman.com/auth?page=demand/"+this.demand.id+"%26userType=CUSTOM%26back=home%26type=shareDemand%26pid="+this.demand.createdUserId+"&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'http://admin.91workman.com/app/img/detail/logo.png', // 分享图标
        success: () => {
          console.info("onMenuShareAppMessage")
        }
      })

    },
    async getPhone(){
      let _this = this;
      if (!this.userInfo.phone){
        Dialog.confirm({
          title: '提醒',
          message: '请先注册完善个人信息',
        })
        .then(() => {
          this.$router.push('my-user-authentication');
        })
        .catch(() => {
          // on cancel
        });
      }else {
        Dialog.confirm({
          title: '联系方式',
          message: '本条信息获取联系方式需要扣除'+this.integral.查看联系方式+'积分',
        })
            .then(() => {
              this.checkPhoneApi();
            })
            .catch(() => {
              // on cancel
            });
      }
    },
    async checkPhone(){//检查是否已经获取过该联系方式
      let r = await demandApi.checkPhone(this.demand.id);
      if (r){
        this.tel = "tel:"+r;
      }
      console.info("checkPhone",this.tel,r)
    },
    showPhone(){
      Dialog.confirm({
        title: '提示',
        message: '点击确定拨打电话：'+this.tel,
        confirmButtonColor: '#09BB07'
      })
          .then(() => {
            // on confirm
            this.$refs.telPhone.click()
          })
    },

    async checkPhoneApi(){//
        let _this = this;
      let r = await demandApi.getPhone(this.demand.id);
      console.info("checkPhoneApi",r)
      if (r){
        this.tel = "tel:"+r;
      }
      Dialog.confirm({
        title: '提示',
        message: '点击确定拨打电话：'+_this.tel,
        confirmButtonColor: '#09BB07'
      })
      .then(() => {
        // on confirm
        this.$refs.telPhone.click()
      })
      .catch(() => {
        // on cancel
      })
    }

  },
};
</script>

<style lang="less" scoped>


.mask {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0 4px 10px 0 rgba(51, 51, 51, 0.2);
}
.maskImg {
  position: fixed;
  top: 6px;
  right: 26px;
  z-index: 100000;
  //width: 507upx;
}
.maskImg img {
  width: 80px;
}
.share-txt{
  color: #fff;
  text-align: center;
  width: 100%;
  margin-top: 60px;
  margin-right: 25px;
  .title{
    font-size: 22px;
  }
}


.page {
  background: #fafafa;
}
h5{
  font-size: 1.1em;
  margin-block-start: 0.9em;
  margin-block-end: 1em;
}
.van-icon{
  top: 2px;
}
.details-box {
  min-height: calc(100vh - 90px);
  .title-box {
    background: #ffffff;
    padding: 10px;

    .title-top {
      font-size: 18px;
      font-weight: 600;

      .salary {
        float: right;
        color: #f38003;
      }
    }

    .title-bottom {
      padding: 5px 0;
      color: #747577;
      font-size: 14px;
    }
  }

  .progress {
    margin-top: 5px;
    background: #ffffff;
    height: 75px;
    padding: 1px 10px;

    .progress-van {
      border-bottom: 1px #f7f7f7 solid;
      height: 60px;
    }
    .card {
      display: flex;
      justify-content: space-between;
      .boss-photo {
        width: 20%;
        padding: 10px 0 10px 10px;
        img {
          width: 45px;
          height: 45px;
        }
      }
      .boss-info {
        width: 80%;
        padding: 10px 10px 10px 0;
        span {
          color: #c3c3c3;
          font-size: 14px;
        }
        .release {
          margin-top: 5px;
          color: #747577;
        }
      }
    }
  }

  .project {
    background: #ffffff;
    /*border: 1px #F38003 solid;*/
    min-height: 220px;
    padding: 0 10px;
    .describe {
      border-bottom: 1px #f7f7f7 solid;
      .tip {
        font-weight: 600;
        font-size: 1.1em;
        margin: 5px 0;
        padding-top: 5px;
      }
      .work-type {
        color: #3c3c3c;
        font-size: 14px;
        margin-bottom: 10px;
        span {
          padding: 3px;
          background: #f0f5ff;
          color: #6e8fd1;
        }
      }
      .treatment {
        font-size: 14px;
        color: #3c3c3c;
      }
      .first {
        border-bottom: 1px #f7f7f7 solid;
        background: #fafafa;
        color: #3c3c3c;
        font-size: 14px;
        padding: 5px;
        min-height: 140px;
      }
    }
  }
  .work-place {
    padding: 0 10px;
    .tip {
      font-weight: 600;
      font-size: 1.1em;
      margin: 5px 0;
      padding-top: 5px;
    }
    .map {
      height: 160px;
      border: 1px solid #f38003;
    }
  }
  .safety-tip {
    border-bottom: 1px #f7f7f7 solid;
    margin-top: 10px;
    margin-bottom: 80px;
    .tip {
      font-weight: 600;
      font-size: 1.1em;
      margin: 5px 10px;
      padding-top: 5px;
      p {
        font-weight: 500;
        font-size: 14px;
        color: #6e6e6e;
        a {
          color: #1083fc;
        }
      }
    }
  }
}

.footer {
  background: #ffffff;
  padding: 15px;
  position: fixed;
  bottom: 45px;
  left: 0;
  right: 0;
  .footer-btn {
    margin-left: 40%;
    display: flex;
  }
}
</style>
